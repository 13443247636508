import { styled } from '@mui/material/styles';

export const ErrorPage = styled('div')`
  text-align: center;
  display: flex;
  height: ${window.innerHeight}px;
  justify-content: center;
  flex-direction: column;
  margin-top: -30px;

  h1 {
    font-size: 28px;
    margin-top: 10px;
    margin-bottom: 14px;
  }

  pre {
    font-size: 16px;
    line-height: 1.5;
    padding-bottom: 24px;
  }

  svg {
    margin: 0 auto;
    transform: scale(0.8);
  }
  button {
    margin: 0 auto;
  }
`;
