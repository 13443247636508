import { AuthError } from '@azure/msal-browser';
import React, { useEffect, useState } from 'react';

import AlertSVG from 'assets/alert-offline.svg';
import { PrimaryButton, SecondaryButton } from 'components/Button';
import { HOME_PATH } from 'config/internalUrlPaths';
import { ddlog } from 'utils/miscellaneous';

import { ErrorPage } from './error.styled';

interface Props {
  title?: string;
  message?: string;
  path?: string;
  goBack?: () => void;
  reload?: () => void;
  hideHomeButton?: boolean;
  error?: AuthError | null;
}

const ErrorScreen = ({
  title = 'Woops!',
  message = 'Something went wrong!',
  goBack = () => (window.location.pathname = HOME_PATH),
  reload = () => document.location.reload(),
  hideHomeButton = false,
  error,
}: Props) => {
  const [isErrorReported, setIsErrorReported] = useState(false);

  useEffect(() => {
    if (error && !isErrorReported) {
      setIsErrorReported(true);
      ddlog.error('error reported from woops screen', error);
    }
  }, [error, isErrorReported]);

  return (
    <ErrorPage>
      <AlertSVG />
      <h1>{title}</h1>
      <pre>{message}</pre>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        <SecondaryButton style={{ margin: 0, marginRight: 4 }} onClick={reload}>
          Reload
        </SecondaryButton>
        {!hideHomeButton && (
          <PrimaryButton style={{ margin: 0, marginLeft: 4 }} onClick={goBack}>
            Home
          </PrimaryButton>
        )}
      </div>
    </ErrorPage>
  );
};

export default ErrorScreen;
